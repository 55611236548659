import {AccountUser, Factory, ReportOptions, Pagination} from 'app/models';
import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API_URL;

export type Engines = {
  code: string;
  name: string;
  settings: string;
  power: string;
  capacity: string;
  id: number;
};

export const saveReport = async (
  body: ReportOptions & {
    autopart_id?: number;
    external_catalog_id?: number;
  }
): Promise<AccountUser> => {
  return axios
    .post(`${API_URL}/autoparts/reports`, body)
    .then((d: AxiosResponse<AccountUser>) => d.data);
};

export const saveAutopartMedia = async (userAutopartMediaInfos: {
  autopart_id: number;
  url: string;
  origin: 'SEARCHED_BY_USER' | 'SELECTED_FROM_EXTERNAL_OPTIONS';
}) => {
  return axios
    .post(`${API_URL}/autopart/medias/send`, userAutopartMediaInfos)
    .then((d: AxiosResponse) => d.data);
};

export const evaluateSearch = async (evaluation: string, searchUid: string) => {
  return axios
    .put(`${API_URL}/search/${searchUid}/evaluate`, {
      evaluation,
    })
    .then((d) => d.data);
};

export const getAutopartsBrands = async (query?: {
  page?: number;
  take?: number;
  name?: string;
}): Promise<{data: Factory[]; pagination: Pagination}> => {
  try {
    const response = await axios.get(`${CATALOG_API_URL}/v1/brands`, {
      params: query,
    });

    if (response?.data) {
      return response.data;
    }

    throw new Error('NO_RESPONSE_TO_GET-AUTOPARTS-BRANDS');
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFactories = async (query?: string): Promise<Factory[]> => {
  try {
    const response = await axios.get(`${API_URL}/factories?${query}`);

    if (response?.data) {
      return response.data;
    }

    throw new Error('no_response_to_get_factories');
  } catch (error) {
    console.error(error);
    throw error;
  }
};
