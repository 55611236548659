import {
  ProductsSearchRequest,
  ProductDetailsResponse,
  ProductsSearchResponse,
  VehicleManufacturersResponse,
  VehicleModelsResponse,
  VehiclePlateResponse,
  VehicleVersionsResponse,
  VehicleYearsResponse,
  VehicleDetailsResponse,
} from 'app/models';
import {OfferV2} from 'app/models/catalog-v2/Offer';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API_URL;
const GET_PRODUCTS_API_URL = `${CATALOG_API_URL}/v1/products`;
const VEHICLES_API_URL = `${CATALOG_API_URL}/v1/vehicles`;
const LIST_OFFERS_API_URL = `${API_URL}/v2/offers/list`;

export const productsSearchV2 = async (
  request: ProductsSearchRequest
): Promise<ProductsSearchResponse> => {
  try {    
    const response = await axios.get(`${GET_PRODUCTS_API_URL}/priced`, {
      params: request,
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to search products: ${(error as Error).message}`);
  }
};

export const getProductDetailsV2 = async ({
  productHash,
  clientAccountUid,
}: {
  productHash: string;
  clientAccountUid: string;
}): Promise<ProductDetailsResponse> => {
  try {
    const response = await axios.get(
      `${GET_PRODUCTS_API_URL}/${productHash}/priced`,
      {
        params: {clientAccountUid},
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get product details: ${(error as Error).message}`
    );
  }
};

export const listOffersV2 = async ({
  productHash,
}: {
  productHash: string;
}): Promise<OfferV2[]> => {
  try {
    const response = await axios.get(`${LIST_OFFERS_API_URL}`, {
      params: {product_hash: productHash},
    });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to list offers: ${(error as Error).message}`);
  }
};

export const getVehicleByPlateV2 = async (
  vehiclePlate: string
): Promise<VehiclePlateResponse> => {
  try {
    const response = await axios.get(
      `${VEHICLES_API_URL}/plate/${vehiclePlate}`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get vehicle by plate: ${(error as Error).message}`
    );
  }
};

export const getVehicleManufacturers = async (
  name?: string
): Promise<VehicleManufacturersResponse> => {
  try {
    const response = await axios.get(`${VEHICLES_API_URL}/manufacturers`, {
      params: {name},
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get vehicle manufacturers: ${(error as Error).message}`
    );
  }
};

export const getVehicleModelsByManufacturersV2 = async (
  manufacturerHash: string,
  name?: string
): Promise<VehicleModelsResponse> => {
  try {
    const response = await axios.get(`${VEHICLES_API_URL}/models`, {
      params: {manufacturerHash, name},
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get vehicle models by manufacturers: ${
        (error as Error).message
      }`
    );
  }
};

export const getVehicleYearsByModelV2 = async (
  modelHash: string
): Promise<VehicleYearsResponse> => {
  try {
    const response = await axios.get(`${VEHICLES_API_URL}/years`, {
      params: {modelHash},
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get vehicle years by model: ${(error as Error).message}`
    );
  }
};

export const getVehicleVersionsByModelAndYearV2 = async (
  year: number,
  modelHash: string
): Promise<VehicleVersionsResponse> => {
  try {
    const response = await axios.get(`${VEHICLES_API_URL}`, {
      params: {year, modelHash},
    });
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get vehicle versions by model and year: ${
        (error as Error).message
      }`
    );
  }
};

export const getVehicleDetails = async (
  vehicleHash: string
): Promise<VehicleDetailsResponse> => {
  try {
    const response = await axios.get(`${VEHICLES_API_URL}/${vehicleHash}`);
    return response.data;
  } catch (error) {
    throw new Error(
      `Failed to get vehicle details: ${(error as Error).message}`
    );
  }
};
