import axios, {AxiosResponse} from 'axios';
import {PurchaseRequest, UpdatePurchaseRequestBody} from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_PURCHASES_URL = `${API_URL}/purchase-requests`;

export const getPurchaseRequests = (
  query?: string
): Promise<PurchaseRequest[]> => {
  return axios
    .get(`${GET_PURCHASES_URL}?${query}`)
    .then((resp: AxiosResponse<PurchaseRequest[]>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const getPurchaseRequestByUid = (
  uid: string,
  query?: string
): Promise<PurchaseRequest> => {
  return axios
    .get(`${GET_PURCHASES_URL}/${uid}${query ? '?' + query : ''}`)
    .then((resp: AxiosResponse<PurchaseRequest>) => resp.data)
    .catch((e) => {
      throw e;
    });
};
