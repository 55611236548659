import { Invoice, InvoiceEntry } from "app/models/Invoice"
import axios, { AxiosResponse } from "axios"

const API_URL = process.env.REACT_APP_FINANCE_API_URL
const INVOICE_API_URL = `${API_URL}/banks/invoice`

export const getInvoices = (params: string): Promise<{result: Invoice[], resultCount: number}> => {
    return axios
      .get(`${API_URL}/invoices?${params}`)
      .then((d: AxiosResponse<{result: Invoice[], resultCount: number}>) => {
        return d.data;
      })
      .catch(e => {
        throw e
      }).finally(() => {
        return []
      })
}

export const getInvoiceByUid = async (uid: string): Promise<Invoice & {invoice_entries: InvoiceEntry[]}> => {
    return axios
      .get(`${API_URL}/invoices/${uid}`)
      .then((d: AxiosResponse<Invoice & {invoice_entries: InvoiceEntry[]}>) => {
        return d.data;
      })
      .catch(e => {
        throw e
      }).finally(() => {
        return []
      })
}

export const reprocessInvoices = (body: any):  Promise<Invoice> => {
  return axios
    .put(`${INVOICE_API_URL}/reprocess`, body)
    .then((d: AxiosResponse<Invoice>) => d.data)
    .catch(e => {
      throw e
    })
}

export const changeInvoiceStatus = (invoiceUid: string, body:{invoiceNewStatus:string}):  Promise<{message: string, updatedInvoice: Invoice}> => {
  return axios
    .put(`${INVOICE_API_URL}/${invoiceUid}/status`, body)
    .then((d: AxiosResponse<{message: string, updatedInvoice: Invoice}>) => d.data)
    .catch(e => {
      throw e
    })
}