import {Suspense, useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import {I18nProvider} from '../_metronic/i18n/i18nProvider';
import {
  LayoutProvider,
  LayoutSplashScreen,
  useLayout,
} from '../_metronic/layout/core';
import {MasterInit} from '../_metronic/layout/MasterInit';
import {AuthInit, useAuth} from './modules/auth';
import Hotjar from '@hotjar/browser';
import useRouteRedirection from './shared/hooks/useRouteRedirection';

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {setLayout, config} = useLayout();
  const {userHasPermissionToAccessRoute} = useRouteRedirection();

  useEffect(() => {
    config.header.width = 'fluid';
    setLayout(config);

    Hotjar.init(
      Number(process.env.REACT_APP_HOTJAR_HJID),
      Number(process.env.REACT_APP_HOTJAR_HJSV)
    );
  }, []);

  if (!userHasPermissionToAccessRoute(location.pathname)) {
    navigate('/error');
  }

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};

export {App};
