import axios, {AxiosResponse} from 'axios';
import {
  CreateQuoteBody,
  CreateQuoteResponse,
  Offer,
  Quote,
  QuoteDiscount,
  QuoteDiscountResponse,
  ToggleQuoteOfferResponse,
  ToggleQuoteOfferResponseV2,
} from '../models';

import QueryString from 'qs';

const API_URL = process.env.REACT_APP_API_URL;
const QUOTES_API_URL = `${API_URL}/quotes`;
const QUOTES_API_URL_V2 = `${API_URL}/v2/quotes`;

type Params = {
  [key: string]: string | boolean | undefined | null;
};

export const getQuotes = (
  query?: string
): Promise<{result: Quote[]; resultCount: number}> => {
  return axios
    .get(`${QUOTES_API_URL}?${query}`)
    .then((d: AxiosResponse<{result: Quote[]; resultCount: number}>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const pullQuoteFromQueue = (): Promise<Quote> => {
  return axios
    .get(`${QUOTES_API_URL}/pull`)
    .then((d: AxiosResponse<Quote>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const countWorkableQuotes = (): Promise<number> => {
  return axios
    .get(`${QUOTES_API_URL}/workable/count`)
    .then((resp: AxiosResponse<number>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const showQuote = (
  quoteUid: string,
  params?: Params
): Promise<Quote> => {
  return axios
    .get(`${QUOTES_API_URL}/${quoteUid}?${QueryString.stringify(params)}`)
    .then((d: AxiosResponse<Quote>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const applyDiscount = (
  quoteUid: string,
  body: QuoteDiscount
): Promise<QuoteDiscountResponse> => {
  return axios
    .put(`${QUOTES_API_URL}/${quoteUid}/quotes-offers/discount`, body)
    .then((d: AxiosResponse<QuoteDiscountResponse>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const transferQuote = (
  quoteUid: string,
  assigned_account_user_uid?: string | null
): Promise<Quote> => {
  return axios
    .put(`${QUOTES_API_URL}/${quoteUid}/transfer`, {assigned_account_user_uid})
    .then((d: AxiosResponse<Quote>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const updateQuoteStatus = (
  quoteUid: string,
  status: string
): Promise<any> => {
  return axios
    .put(`${QUOTES_API_URL}/${quoteUid}`, {status})
    .then((d: AxiosResponse<ToggleQuoteOfferResponse>) => d.data?.quote)
    .catch((e) => {
      throw new Error(e);
    });
};

export const closeQuoteStatus = (quoteUid: string, data: any): Promise<any> => {
  return axios
    .put(`${QUOTES_API_URL}/${quoteUid}/close`, data)
    .then((d: AxiosResponse<ToggleQuoteOfferResponse>) => d.data?.quote)
    .catch((e) => {
      throw new Error(e);
    });
};

export const cancelQuote = (
  quoteUid: string,
  cancelingReason: string
): Promise<Quote | undefined> => {
  return axios
    .put(`${QUOTES_API_URL}/${quoteUid}/cancel`, {
      cancelation_reason: cancelingReason,
    })
    .then((d: AxiosResponse<Quote | undefined>) => d.data)
    .catch((e) => {
      throw new Error(e);
    });
};

export const rejectQuote = (
  quoteUid: string,
  rejectReason: string,
  status: string
): Promise<Quote | undefined> => {
  return axios
    .put(`${QUOTES_API_URL}/${quoteUid}/reject`, {
      status,
      rejection_reason: rejectReason,
    })
    .then((d: AxiosResponse<Quote | undefined>) => d.data)
    .catch((e) => {
      throw new Error(e);
    });
};

export const buildOfferToggleBody = (
  offer: Offer,
  quoteRequestItemId?: number
): Offer => {
  return {
    offer_id: offer.id,
    calculated_price_uid: offer.calculated_price_uid,
    offer_provider_name: offer.offer_provider_name,
    stock_units: offer.stock_units,
    offer_provider_reference: offer.offer_provider_reference,
    offer_provider_uid: offer.offer_provider_uid,
    delivery_type: offer.delivery_type,
    warehouse_uid: offer.warehouse_uid,
    package_quantity: offer.quantity_per_package,
    price_tracked_at: new Date().toISOString(),
    client_unit_price: offer.client_unit_price,
    estimated_purchase_unit_price:
      offer.estimated_purchase_unit_price || offer.client_unit_price,
    quantity_type: offer.quantity_type,
    offer_factory_code: offer.offer_factory_code,
    offer_brand_name: offer.offer_brand_name,
    description: offer.description,
    autopart_id: offer.autopart_id,
    offer_image_url: offer.offer_image_url,
    search_uid: offer.search_uid,
    component_id: offer.component_id,
    offer_hash: offer.offer_hash,
    item_code: offer.item_code,
    item_id: offer.item_id,
    logistic_price_to_mecanizou: 0,
    quote_request_item_id: quoteRequestItemId || offer.quote_request_item_id,
    factory_uid: offer.factory_uid,
    item_hash: offer.item_hash,
  };
};

export const toggleQuoteOffer = async (
  quoteUid: string,
  offer: Offer
): Promise<any> => {
  return axios
    .post(`${QUOTES_API_URL}/${quoteUid}/quote-offers`, offer)
    .then((d: AxiosResponse<ToggleQuoteOfferResponse>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const createQuote = (
  body: CreateQuoteBody
): Promise<CreateQuoteResponse> => {
  return axios
    .post(`${QUOTES_API_URL_V2}`, body)
    .then((resp: AxiosResponse<CreateQuoteResponse>) => resp.data);
};

export const toggleQuoteOfferV2 = async ({
  quoteUid,
  quote_offer,
  product,
}: {
  quoteUid: string;
  quote_offer?: {quote_offer: Offer};
  product?: {
    product_hash: string;
    quote_request_item_id: number;
    calculated_price_uid: string;
  };
}): Promise<ToggleQuoteOfferResponseV2> => {
  return axios
    .post(
      `${QUOTES_API_URL_V2}/${quoteUid}/quote-offers`,
      quote_offer || product
    )
    .then((d: AxiosResponse<ToggleQuoteOfferResponseV2>) => d.data)
    .catch((e) => {
      throw e;
    });
};
