import {Customer} from 'app/models/Customer';
import axios, {AxiosResponse} from 'axios';
import {
  Address,
  Microzone,
  NewProviderModel,
  Provider,
  ProviderStock,
  ProviderStockInfo,
} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const PROVIDERS_URL = `${API_URL}/providers`;
const PROVIDERS_STOCKS_URL = `${API_URL}/provider-stocks`;
const CUSTOMERS_URL = `${API_URL}/customers`;
const ADDRESSES_URL = `${API_URL}/addresses`;

export const getProviders = async (
  query?: string
): Promise<{result: Provider[]; resultCount: number}> => {
  const response: AxiosResponse<{result: Provider[]; resultCount: number}> =
    await axios.get(`${PROVIDERS_URL}?${query}`);

  if (response) {
    return response.data;
  }

  throw new Error('REQUESTS_COULD_NOT_GET_PROVIDERS');
};

export const getProvidersHashtable = async (
  query?: string
): Promise<{[uid: string]: string}> => {
  const response: AxiosResponse<{result: Provider[]; resultCount: number}> =
    await axios.get(`${PROVIDERS_URL}?${query}`);

  if (response) {
    const providersHashtable: {[uid: string]: string} = {};
    for (const provider of response.data.result) {
      providersHashtable[provider.uid] = provider.name;
    }
    return providersHashtable;
  }

  throw new Error('REQUESTS_COULD_NOT_GET_PROVIDERS');
};

export const getProviderByUid = (uid: string): Promise<Provider> => {
  return axios
    .get(`${PROVIDERS_URL}/${uid}`)
    .then((d: AxiosResponse<Provider>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getProviderStockByUid = (
  warehouseUid: string
): Promise<ProviderStock> => {
  return axios
    .get(`${PROVIDERS_URL}/stocks/${warehouseUid}`)
    .then((d: AxiosResponse<ProviderStock>) => d.data)
    .catch((e) => {
      throw e;
    });
};
export const getAllWarehouseByUid = () => {
  return axios
    .get(`${PROVIDERS_URL}/stocks?only_integrated=true`)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const createProvider = (body: NewProviderModel): Promise<Provider> => {
  return axios
    .post(`${PROVIDERS_URL}/autopart`, body)
    .then((d: AxiosResponse<Provider>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const updateProvider = (
  uid: string,
  body: NewProviderModel
): Promise<Provider> => {
  return axios
    .put(`${PROVIDERS_URL}/autopart/${uid}/update`, body)
    .then((d: AxiosResponse<Provider>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getProviderProductTypes = () => {
  return axios
    .get(`${PROVIDERS_URL}/product-types`)
    .then((d: AxiosResponse<{id: number; name: string}[]>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getProviderPurchaseTypes = () => {
  return axios
    .get(`${PROVIDERS_URL}/purchase-types`)
    .then((d: AxiosResponse<{id: number; name: string}[]>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getNearbyWarehouse = ({
  addressUid,
  warehouseUid,
  limitOfReturnedOptions,
}: {
  addressUid: string;
  warehouseUid?: string;
  limitOfReturnedOptions?: number;
}) => {
  return axios
    .get(`${PROVIDERS_STOCKS_URL}/nearby-stocks/${addressUid}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        warehouseUid,
        limitOfReturnedOptions: limitOfReturnedOptions || 50,
      },
    })
    .then((d: AxiosResponse<ProviderStockInfo[]>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const showCustomerInfo = (accountUid: string): Promise<Customer> => {
  return axios
    .get(`${CUSTOMERS_URL}/accounts/${accountUid}`)
    .then((resp: AxiosResponse<Customer>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const createCustomer = (body: any): Promise<Customer> => {
  return axios
    .post(`${CUSTOMERS_URL}`, body)
    .then((d: AxiosResponse<Customer>) => d.data)
    .catch((e) => {
      throw e.data;
    });
};

export const updateCustomer = (
  customerUid: string,
  body: any
): Promise<Customer> => {
  return axios
    .put(`${CUSTOMERS_URL}/${customerUid}`, body)
    .then((d: AxiosResponse<Customer>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getCustomersNameHashtable = (
  clientAccountUids: string[]
): Promise<{[key: string]: string}> => {
  return axios
    .get(
      `${CUSTOMERS_URL}?clientAccountUids=${JSON.stringify(clientAccountUids)}`
    )
    .then((d: AxiosResponse<any>) => {
      const hashtable: {[key: string]: string} = {};
      for (const {client_account_uid, fantasy_name} of d.data.result) {
        hashtable[client_account_uid] = fantasy_name;
      }
      return hashtable;
    })
    .catch((e) => {
      throw e;
    });
};

export const listCategories = async (): Promise<
  {id: number; name: string}[]
> => {
  return await axios
    .get(`${API_URL}/autopart-categories`)
    .then((d: AxiosResponse<{id: number; name: string}[]>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const listBrands = async (): Promise<{id: number; name: string}[]> => {
  return await axios
    .get(`${API_URL}/vehicle-brands`)
    .then((d: AxiosResponse<{id: number; name: string}[]>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const sendMecaniVarMessage = async (body: any): Promise<any> => {
  return await axios
    .post(`${API_URL}/providers/send-message`, body)
    .then((d: AxiosResponse<any>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const getDistanceBetweenTwoAddresses = async (
  firstAddressUid: string,
  secondAddressUid: string
): Promise<{
  firstAddress: Address;
  secondAddress: Address;
  distance: number;
}> => {
  return await axios
    .get(`${ADDRESSES_URL}/distance/${firstAddressUid}/${secondAddressUid}`)
    .then(
      (
        d: AxiosResponse<{
          firstAddress: Address;
          secondAddress: Address;
          distance: number;
        }>
      ) => d.data
    )
    .catch((e) => {
      throw e;
    });
};
