/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {useLayout} from '../../core';
import {toAbsoluteUrl} from '../../../helpers';
import {AsideMenu} from './AsideMenu';
import useRouteRedirection from 'app/shared/hooks/useRouteRedirection';

type Props = {
  toggleMenu: () => void;
  isMenuOpen: boolean;
};
const AsideDefault: FC<Props> = ({toggleMenu, isMenuOpen}) => {
  const {classes} = useLayout();
  const {routeRedirectionByUser} = useRouteRedirection();

  return (
    <div
      id='kt_aside'
      className={clsx(
        `${isMenuOpen ? 'w-275px' : ''} aside bg-primary scroll-y`,
        classes.aside.join(' ')
      )}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width='auto'
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_toggle'
    >
      <div className='d-flex justify-content-end mt-5 me-3'>
        <i
          className={`${
            isMenuOpen ? 'bi bi-arrow-left' : 'bi bi-arrow-right'
          } cursor-pointer mb-5 fs-1 text-white`}
          onClick={toggleMenu}
        ></i>
      </div>
      {/* begin::Logo */}
      <div
        className='aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto pt-3 pb-8'
        id='kt_aside_logo'
      >
        <Link to={routeRedirectionByUser('/quotes/workspace')}>
          <img src={toAbsoluteUrl('/media/logos/logo-demo4.svg')} alt='logo' />
        </Link>
      </div>
      {/* end::Logo */}

      {/* begin::Nav */}
      <div
        className='asaside-nav d-flex flex-column align-lg-center flex-column-fluid w-100 pt-5 pt-lg-0'
        id='kt_aside_nav'
      >
        <AsideMenu
          asideMenuCSSClasses={classes.asideMenu}
          isMenuOpen={isMenuOpen}
        />
      </div>
      {/* end::Nav */}

      {/* begin::Footer */}
      {/* <div
        className='aside-footer d-flex flex-column align-items-center flex-column-auto'
        id='kt_aside_footer'
      >
        <div className='mb-7'>
          <button
            type='button'
            className='btn btm-sm btn-icon btn-color-white btn-active-color-primary btn-active-light'
            data-kt-menu-trigger='click'
            data-kt-menu-overflow='true'
            data-kt-menu-placement='top-start'
            data-bs-toggle='tooltip'
            data-bs-placement='right'
            data-bs-dismiss='click'
            title='Quick actions'
          >
            <KTSVG
              path='/media/icons/duotune/general/gen008.svg'
              className='svg-icon-2 svg-icon-lg-1'
            />
          </button>
          <Dropdown2 />
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  );
};

export {AsideDefault};
