import axios, {AxiosResponse} from 'axios';
import {
  GetRangeLogisticsPriceBody,
  GetRangeLogisticsPriceResponse,
  Offer,
} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const QUOTE_OFFERS_API_URL = `${API_URL}/quote-offers`;

export const getRangeLogisticsPrice = (
  body: GetRangeLogisticsPriceBody
): Promise<GetRangeLogisticsPriceResponse> => {
  return axios
    .post(`${QUOTE_OFFERS_API_URL}/range-logistics-price`, body)
    .then((d: AxiosResponse<GetRangeLogisticsPriceResponse>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const putQuoteOffer = (
  quoteOfferUid: string,
  quoteOffer: {description: string; offer_image_url: string | null}
) => {
  return axios.put(`${API_URL}/quotes-offers/${quoteOfferUid}`, quoteOffer);
};

export const postQuoteOfferLog = async (
  body: Offer,
  itemDescription: string,
  searchDescription?: string
) => {
  return axios.post(`${API_URL}/quote-offer/logs`, {
    is_custom: body.is_custom || false,
    quote_request_item_id: body.quote_request_item_id,
    quote_request_item_description: itemDescription,
    is_vehicle_ignored_on_search: body.is_vehicle_ignored_on_search || false,
    is_initial_search:
      !searchDescription || searchDescription === itemDescription,
    product_description: body.description,
    searched_term: searchDescription || itemDescription,
    autopart_id: body.autopart_id,
    external_catalog_id: body.external_catalog_id,
  });
};
