import axios, {AxiosResponse} from 'axios';
import {stringifySimpleRequestQuery} from '../../_metronic/helpers';
import {
  QueryArrItem,
  ShowVehicleBrandsResponse,
  ShowVehicleModelsResponse,
  ShowVehicleModelYearsResponse,
  ShowVehicleVersionsResponse,
} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_VEHICLES_URL = `${API_URL}/vehicles`;


export const showVehicleBrands = (
  queryArr: QueryArrItem[]
): Promise<ShowVehicleBrandsResponse[]> => {
  const query = stringifySimpleRequestQuery(queryArr);

  return axios
    .get(`${GET_VEHICLES_URL}/brands/list?${query}`)
    .then((d: AxiosResponse<ShowVehicleBrandsResponse[]>) => d.data);
};

export const showVehicleModelsByBrand = (
  brandUid: string,
  queryArr: QueryArrItem[]
): Promise<ShowVehicleModelsResponse[]> => {
  const query = stringifySimpleRequestQuery(queryArr);

  return axios
    .get(`${GET_VEHICLES_URL}/brands/${brandUid}/models/list?${query}`)
    .then((d: AxiosResponse<ShowVehicleModelsResponse[]>) => d.data);
};

export const showVehicleModelYearsByModel = (
  modelUid: string
): Promise<ShowVehicleModelYearsResponse[]> => {
  return axios
    .get(`${GET_VEHICLES_URL}/models/${modelUid}/model-years/list`)
    .then((d: AxiosResponse<ShowVehicleModelYearsResponse[]>) => d.data);
};

export const showVehicleVersionsByModel = (
  modelUid: string,
  queryArr?: QueryArrItem[]
): Promise<ShowVehicleVersionsResponse[]> => {
  const query = queryArr ? stringifySimpleRequestQuery(queryArr) : '';

  return axios
    .get(`${GET_VEHICLES_URL}/models/${modelUid}/versions/list?${query}`)
    .then((d: AxiosResponse<ShowVehicleVersionsResponse[]>) => d.data);
};
