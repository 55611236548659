import { ClientTaxReceipt, ProviderTaxReceipt } from 'app/models';
import axios, { AxiosResponse } from 'axios';

const API_URL = process.env.REACT_APP_FINANCE_API_URL;
const MECANICITY_API_URL = process.env.REACT_APP_API_URL;

export const getTaxReceipts = async (
  params: string
): Promise<{ result: ClientTaxReceipt[]; resultCount: number }> => {
  return axios
    .get(`${API_URL}/lion/tax-receipts/outgoing/sale?${params}`)
    .then(
      (d: AxiosResponse<{ result: ClientTaxReceipt[]; resultCount: number }>) => {
        return d.data;
      }
    )
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const getReturnTaxReceipts = async (
  params: string
): Promise<{ result: ProviderTaxReceipt[]; resultCount: number }> => {
  return axios
    .get(`${API_URL}/lion/tax-receipts/outgoing/return?${params}`)
    .then(
      (d: AxiosResponse<{ result: ProviderTaxReceipt[]; resultCount: number }>) => {
        return d.data;
      }
    )
    .catch((e) => {
      throw e;
    })
    .finally(() => {
      return [];
    });
};

export const createWeeklyTaxReceipts = (
  startDate: string | Date,
  endDate: string | Date
): Promise<string> => {
  return axios
    .post(`${MECANICITY_API_URL}/orders/generate-tax-receipts`, {
      startDate,
      endDate,
    })
    .then((d: AxiosResponse<string>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const uploadTaxReceiptFromXml = ({
  xml, accessKey
}: {
  xml: string;
  accessKey: string;
}): Promise<string> => {
  return axios
    .post(`${API_URL}/lion/tax-receipts/incoming/${accessKey}`, { xml })
    .then((d: AxiosResponse<string>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const cancelTaxReceipt = ({
  taxReceiptUid,
  reasonToCancel,
}: {
  taxReceiptUid: string;
  reasonToCancel: string;
}) => {
  return axios
    .delete(`${API_URL}/lion/tax-receipts/outgoing/${taxReceiptUid}`, {
      data: { reason: reasonToCancel },
    })
    .then((d: AxiosResponse<string>) => d.data)
    .catch((e) => {
      throw e;
    });
};

