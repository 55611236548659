import axios from 'axios';
import {
  Delivery,
  IncidentDeliveryResponseData,
  NewDeliveryModel,
  DeliveryCancelReasons,
} from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;
const DELIVERIES_URL = `${API_URL}/deliveries`;

export const getDeliveries = async (
  query?: string
): Promise<{result: Delivery[]; resultCount: number}> => {
  try {
    const resp = await axios.get(`${DELIVERIES_URL}?${query}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const getIncidentDeliveries = async (
  query?: string
): Promise<{result: IncidentDeliveryResponseData[]; resultCount: number}> => {
  try {
    const resp = await axios.get(`${DELIVERIES_URL}/incident?${query}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const getDeliveryByUid = async (uid: string): Promise<Delivery> => {
  try {
    const resp = await axios.get(`${DELIVERIES_URL}/${uid}`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const transferDelivery = async (
  uid: string,
  body: {destination_user_account_uid: string}
): Promise<Delivery> => {
  try {
    const resp = await axios.put(`${DELIVERIES_URL}/${uid}/transfer`, body);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const assignDelivery = async (uid: string): Promise<Delivery> => {
  try {
    const resp = await axios.get(`${DELIVERIES_URL}/${uid}/assign`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const unassignDelivery = async (uid: string): Promise<Delivery> => {
  try {
    const resp = await axios.put(`${DELIVERIES_URL}/${uid}/unassign`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const updateDelivery = async (
  uid: string,
  data: any
): Promise<Delivery> => {
  try {
    const resp = await axios.put(`${DELIVERIES_URL}/${uid}`, data);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const finishDelivery = async (
  deliveryUid: string
): Promise<Delivery> => {
  try {
    const resp = await axios.put(`${DELIVERIES_URL}/${deliveryUid}/finish`);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const registerDelivery = async (
  uid: string,
  body: NewDeliveryModel
): Promise<any> => {
  try {
    const resp = await axios.put(`${DELIVERIES_URL}/${uid}/register`, body);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const registerDeliveryCancel = async (
  uid: string,
  body: {reason: DeliveryCancelReasons; otherReason?: string}
): Promise<Delivery> => {
  try {
    const resp = await axios.put(
      `${DELIVERIES_URL}/${uid}/cancel-register`,
      body
    );
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const requestDeliveryCancel = async (
  uid: string,
  body: {reason: DeliveryCancelReasons; otherReason?: string}
): Promise<Delivery> => {
  try {
    const resp = await axios.put(
      `${DELIVERIES_URL}/${uid}/cancel-request`,
      body
    );
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const requestAutomaticDelivery = async (
  uid: string,
  body: {logisticsProviderUid?: string; logisticsServiceType: string}
): Promise<{deliveryUid: string}> => {
  try {
    const resp = await axios.post(`${DELIVERIES_URL}/${uid}/request`, body);
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const requestDriverChange = async (
  uid: string,
  reason: string
): Promise<{deliveryUid: string}> => {
  try {
    const resp = await axios.put(`${DELIVERIES_URL}/${uid}/driver-change`, {
      reason,
    });
    return resp.data;
  } catch (e) {
    throw e;
  }
};

export const addPriorityFee = async (
  deliveryUid: string,
  body: {priority_fee: number}
): Promise<Delivery> => {
  try {
    const resp = await axios.post(
      `${DELIVERIES_URL}/${deliveryUid}/priority-fee`,
      body
    );
    return resp.data;
  } catch (e) {
    throw e;
  }
};
