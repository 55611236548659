import {lazy, FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper';
import {MenuTestPage} from '../pages/MenuTestPage';
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils';
import useRouteRedirection from 'app/shared/hooks/useRouteRedirection';

const PrivateRoutes = () => {
  const {routeRedirectionByUser} = useRouteRedirection();

  const BuilderPageWrapper = lazy(
    () => import('../pages/layout-builder/BuilderPageWrapper')
  );
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../modules/accounts/MembersPage'));
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'));
  // const UsersPage = lazy(() => import('../modules/users/UsersPage'))

  const CustomerPage = lazy(() => import('app/pages/Customer/CustomerPage'));
  const MembersPage = lazy(() => import('../modules/accounts/MembersPage'));
  const QuotesPage = lazy(() => import('../modules/quotes/QuotesPage'));
  const OrdersPage = lazy(() => import('../modules/orders/OrdersPage'));
  const AutopartsPage = lazy(() => import('../modules/catalog/CatalogPage'));
  const PurchasePage = lazy(() => import('../modules/purchases/PurchasePage'));
  const CouponPage = lazy(() => import('app/pages/Coupon/CouponPage'));
  const CommunicationPage = lazy(
    () => import('../modules/communication/CommunicationPage')
  );
  const CancelledItemsPage = lazy(
    () => import('../modules/canceledItems/CancelledItemsPage')
  );
  const OrderItemsPage = lazy(
    () => import('../modules/orderItems/OrderItemsPage')
  );
  const LogisticsPage = lazy(
    () => import('../modules/logistics/LogisticsPage')
  );
  const IncidentDeliveriesPage = lazy(
    () => import('../modules/incidentDeliveries/IncidentDeliveriesPage')
  );
  const AutopartProviderPage = lazy(
    () => import('app/pages/AutopartProvider/AutopartProviderPage')
  );
  const PaymentPage = lazy(() => import('../modules/finance/FinancePage'));

  return (
    <Routes>
      {/* Redirect to Dashboard after success login/registartion */}
      <Route
        path='auth/*'
        element={<Navigate to={routeRedirectionByUser('/quotes/workspace')} />}
      />
      <Route element={<MasterLayout />}>
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/accounts/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='mechanics/*'
          element={
            <SuspensedView>
              <CustomerPage />
            </SuspensedView>
          }
        />
        <Route
          path='members/*'
          element={
            <SuspensedView>
              <MembersPage />
            </SuspensedView>
          }
        />

        <Route
          path='orders/*'
          element={
            <SuspensedView>
              <OrdersPage />
            </SuspensedView>
          }
        />

        <Route
          path='catalog/*'
          element={
            <SuspensedView>
              <AutopartsPage />
            </SuspensedView>
          }
        />

        <Route
          path='deliveries/*'
          element={
            <SuspensedView>
              <LogisticsPage />
            </SuspensedView>
          }
        />

        <Route
          path='cancelled-items/*'
          element={
            <SuspensedView>
              <CancelledItemsPage />
            </SuspensedView>
          }
        />

        <Route
          path='order-items/*'
          element={
            <SuspensedView>
              <OrderItemsPage />
            </SuspensedView>
          }
        />

        <Route
          path='providers/*'
          element={
            <SuspensedView>
              <AutopartProviderPage />
            </SuspensedView>
          }
        />

        <Route
          path='finance/*'
          element={
            <SuspensedView>
              <PaymentPage />
            </SuspensedView>
          }
        />

        <Route
          path='coupons/*'
          element={
            <SuspensedView>
              <CouponPage />
            </SuspensedView>
          }
        />

        <Route
          path='communication/*'
          element={
            <SuspensedView>
              <CommunicationPage />
            </SuspensedView>
          }
        />

        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
      <Route
        path='quotes/*'
        element={
          <SuspensedView>
            <QuotesPage />
          </SuspensedView>
        }
      />
      <Route
        path='purchases/*'
        element={
          <SuspensedView>
            <PurchasePage />
          </SuspensedView>
        }
      />
      <Route
        path='incident-deliveries/*'
        element={
          <SuspensedView>
            <IncidentDeliveriesPage />
          </SuspensedView>
        }
      />
    </Routes>
  );
};

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {PrivateRoutes};
