import axios, {AxiosResponse} from 'axios';
import {
  EditPurchaseRequestData,
  GetIntegratedPurchaseProvidersResponseData,
  Purchase,
  RegisterPurchaseRequestData,
} from 'app/models';

const API_URL = process.env.REACT_APP_API_URL;
const PURCHASES_URL = `${API_URL}/purchases`;

export const getPurchases = (
  query: string
): Promise<{result: Purchase[]; resultCount: number}> => {
  return axios
    .get(`${PURCHASES_URL}?${query}`)
    .then(
      (resp: AxiosResponse<{result: Purchase[]; resultCount: number}>) =>
        resp.data
    );
};

export const registerPurchase = (
  orderUid: string,
  body: RegisterPurchaseRequestData
): Promise<Purchase> => {
  return axios
    .post(`${PURCHASES_URL}/${orderUid}/register`, body)
    .then((resp: AxiosResponse<Purchase>) => resp.data);
};

export const editPurchase = (
  body: EditPurchaseRequestData,
  purchaseUid: string
): Promise<Purchase> => {
  return axios
    .put(`${PURCHASES_URL}/${purchaseUid}`, body)
    .then((resp: AxiosResponse<Purchase>) => resp.data);
};

export const cancelPurchase = (purchaseUid: string) => {
  return axios
    .delete(`${PURCHASES_URL}/${purchaseUid}/cancel`)
    .then((resp: AxiosResponse<Purchase>) => resp.data);
};

export const getIntegratedPurchaseProviders = () => {
  return axios
    .get(`${PURCHASES_URL}/integrated-purchase-providers`)
    .then(
      (d: AxiosResponse<GetIntegratedPurchaseProvidersResponseData>) => d.data
    )
    .catch((e) => {
      throw e;
    });
};

export const toggleIntegratedPurchaseProviders = (body: {
  [key: string]: boolean;
}) => {
  return axios
    .put(`${PURCHASES_URL}/integrated-purchase-providers`, body)
    .then(
      (d: AxiosResponse<GetIntegratedPurchaseProvidersResponseData>) => d.data
    )
    .catch((e) => {
      throw e;
    });
};
