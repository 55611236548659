import axios, {AxiosResponse} from 'axios';
import {
  CreateNewQuoteRequestBody,
  CreateQuoteRequestResponse,
  QuoteRequest,
  QuoteRequestsQueryResponse,
} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_QUOTEREQUESTS_URL = `${API_URL}/quote-requests`;
const QUOTE_REQUESTS_API_URL_V2 = `${API_URL}/v2/quote-requests`;

export const getQuoteRequests = (
  query: string
): Promise<{result: QuoteRequestsQueryResponse; resultCount: number}> => {
  return axios.get(`${GET_QUOTEREQUESTS_URL}?${query}`).then(
    (
      resp: AxiosResponse<{
        result: QuoteRequestsQueryResponse;
        resultCount: number;
      }>
    ) => resp.data
  );
};

export const showQuoteRequest = (uid: string): Promise<QuoteRequest> => {
  return axios
    .get(`${GET_QUOTEREQUESTS_URL}/${uid}`)
    .then((resp: AxiosResponse<QuoteRequest>) => resp.data);
};

export const createNewQuoteRequest = (body: any): Promise<any> => {
  return axios
    .post(`${GET_QUOTEREQUESTS_URL}`, body)
    .then((resp: AxiosResponse<any>) => resp.data);
};

export const createNewQuoteRequestV2 = (
  body: CreateNewQuoteRequestBody
): Promise<CreateQuoteRequestResponse> => {
  return axios
    .post(`${QUOTE_REQUESTS_API_URL_V2}`, body)
    .then((resp: AxiosResponse<CreateQuoteRequestResponse>) => resp.data);
};
