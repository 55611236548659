import axios, {AxiosResponse} from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const CATALOG_API_URL = process.env.REACT_APP_CATALOG_API_URL;

export const getSuggestionByTerm = async (term: string) => {
  return axios
    .get(`${API_URL}/v1/autocomplete?search_term=${term}`)
    .then(
      (
        d: AxiosResponse<
          {item_id: number; item_code: string; suggestion: string}[]
        >
      ) => d.data
    );
};

export const getAutocompleteSearchOptions = async (
  searchTerm: string
): Promise<
  {
    suggestion: string;
    itemName: string;
    suggestionType: string;
    itemHash: string;
  }[]
> => {
  try {
    const response = await axios.get(
      `${CATALOG_API_URL}/v1/category-tree/autocomplete?searchTerm=${searchTerm}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error(
      `Failed to get autocomplete search options: ${(error as Error).message}`
    );
  }
};
