import axios, {AxiosResponse} from 'axios';
import {Order, OrderItem, OrderStatusTrackingData, PurchaseRequest} from '../models';
import {
  OrderPurchase,
  OrderPurchaseWithSuggestions,
} from 'app/modules/purchases/Purchase.types';

const API_URL = process.env.REACT_APP_API_URL;
const GET_ORDERS_URL = `${API_URL}/orders`;
const ORDERS_STATUS_TRACKER_URL = process.env.REACT_APP_TRACKER_SERVICE_URL;

type updateOrderItemsStatusBody = {
  orderItems: Array<{uid: string; quantity: number}>;
  cancelation_reasons?: string;
  cancelation_reasons_details?: string;
  incident_event: string;
  status: string;
  itemsLocation?: string;
  mustCallLogisticsImmediately?: boolean;
};

type updateOrderItemsReasonsBody = {
  cancelation_reasons: string;
  cancelation_reasons_details?: string;
};

type ApplyOrderItemsDiscountBody = {
  selectedUids: string[];
  discountType: 'absolut' | 'percentage';
  discountValue: number;
};

export const getOrders = (
  query: string
): Promise<{result: Order[]; resultCount: number}> => {
  return axios
    .get(`${GET_ORDERS_URL}?${query}`)
    .then(
      (resp: AxiosResponse<{result: Order[]; resultCount: number}>) => resp.data
    );
};

export const getOrderPurchases = (
  query?: string
): Promise<{orders: OrderPurchase[]; resultCount: number}> => {
  return axios
    .get(`${GET_ORDERS_URL}/purchase?${query}`)
    .then(
      (resp: AxiosResponse<{orders: OrderPurchase[]; resultCount: number}>) =>
        resp.data
    )
    .catch((e) => {
      throw e;
    });
};

export const showOrder = (orderUid?: string): Promise<Order> => {
  return axios
    .get(`${GET_ORDERS_URL}/${orderUid}`)
    .then((resp: AxiosResponse<Order>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const showOrderPurchase = (
  orderUid?: string
): Promise<OrderPurchaseWithSuggestions> => {
  return axios
    .get(`${GET_ORDERS_URL}/${orderUid}/purchase`)
    .then((resp: AxiosResponse<OrderPurchaseWithSuggestions>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const pullOrderPurchasesFromQueue = ({
  priority,
}: {
  priority: boolean;
}): Promise<PurchaseRequest[]> => {
  return axios
    .patch(`${GET_ORDERS_URL}/pull?priority=${priority}`)
    .then((resp: AxiosResponse<PurchaseRequest[]>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const transferOrderPurchaseAssignment = ({
  orderUid,
  assigned_account_user_uid,
}: {
  orderUid: string;
  assigned_account_user_uid: string | null;
}): Promise<OrderPurchase> => {
  return axios
    .patch(`${GET_ORDERS_URL}/${orderUid}/purchase-requests`, {
      assignedAccountUserUid: assigned_account_user_uid,
    })
    .then((resp: AxiosResponse<OrderPurchase>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const transferOrderPurchasePriority = ({
  orderUid,
  priority,
}: {
  orderUid: string;
  priority: boolean;
}): Promise<OrderPurchase> => {
  return axios
    .patch(`${GET_ORDERS_URL}/${orderUid}/purchase-requests`, {
      priority,
    })
    .then((resp: AxiosResponse<OrderPurchase>) => resp.data)
    .catch((e) => {
      throw e;
    });
};

export const cancelOrderItems = (
  orderUid: string,
  body: updateOrderItemsStatusBody
): Promise<Order> => {
  return axios
    .put(`${GET_ORDERS_URL}/${orderUid}/items/cancel`, body)
    .then((resp: AxiosResponse<Order>) => resp.data);
};

export const applyOrderItemsDiscount = (
  orderUid: string,
  body: ApplyOrderItemsDiscountBody
): Promise<void> => {
  return axios
    .put(`${GET_ORDERS_URL}/${orderUid}/items/discount`, body)
    .then((resp: AxiosResponse<void>) => resp.data);
};

export const updateOrderItemsReasons = (
  orderItemUid: string,
  body: updateOrderItemsReasonsBody
): Promise<OrderItem> => {
  return axios
    .put(`${API_URL}/order-items/${orderItemUid}/change-cancel-reasons`, body)
    .then((resp: AxiosResponse<OrderItem>) => resp.data);
};

export const listOrderItemsWithoutItemCode = (
  query?: string
): Promise<{
  orderItems: OrderItem[];
  take: number;
  page: number;
  count: number;
}> => {
  return axios.get(`${API_URL}/order-items/without-item-code?${query}`).then(
    (
      resp: AxiosResponse<{
        orderItems: OrderItem[];
        take: number;
        page: number;
        count: number;
      }>
    ) => resp.data
  );
};

export const addItemCodeToOrderItem = ({
  uid,
  itemCode,
  itemId,
}: {
  uid: string;
  itemCode: string;
  itemId: number;
}): Promise<OrderItem> => {
  return axios
    .put(`${API_URL}/order-items/${uid}/item-code`, {itemCode, itemId})
    .then((resp: AxiosResponse<OrderItem>) => resp.data);
};

export const getOrderStatusTracking = (referenceId: number): Promise<OrderStatusTrackingData> => {
  return axios
    .get(`${ORDERS_STATUS_TRACKER_URL}/tracker/orders/${referenceId}`)
    .then((resp: AxiosResponse<OrderStatusTrackingData>) => resp.data);
};
