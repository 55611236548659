import {useAuth} from 'app/modules/auth';

const defaultRoutesAllowed = ['auth', 'error'];

const useRouteRedirection = () => {
  const {currentUser} = useAuth();

  const routeRedirectionByUser = (path: string) => {
    if (currentUser?.role === 'ROLE_THIRD_PARTY_STOCK_MANAGER') {
      return '/incident-deliveries/tracking';
    }
    return path;
  };

  const userHasPermissionToAccessRoute = (pathname: string) => {
    if (currentUser?.role === 'ROLE_THIRD_PARTY_STOCK_MANAGER') {
      const allowedPaths = [...defaultRoutesAllowed, 'incident-deliveries'];
      if (
        !allowedPaths.some((path) => pathname.includes(path)) &&
        pathname !== '/'
      ) {
        return false;
      }
    }
    return true;
  };

  return {routeRedirectionByUser, userHasPermissionToAccessRoute};
};

export default useRouteRedirection;
