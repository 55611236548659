import axios, {AxiosResponse} from 'axios';
import {QuoteRequestItem} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const QUOTE_REQUEST_ITEMS_API_URL = `${API_URL}/quote-request-items`;

export const createNewQuoteRequestItem = (body: {
  description: string;
  quote_request_id: number;
}): Promise<QuoteRequestItem[]> => {
  return axios
    .post(`${QUOTE_REQUEST_ITEMS_API_URL}`, body)
    .then((resp: AxiosResponse<QuoteRequestItem[]>) => resp.data);
};
