import axios, {AxiosResponse} from 'axios';
import {
  Account,
  CustomerAccount,
  CreateAccountResponse,
  AccountsQueryResponse,
  MecanizouAccountsQueryResponse,
  InvitedUser,
} from '../models';

const API_URL = process.env.REACT_APP_API_URL;
const GET_ACCOUNTS_URL = `${API_URL}/accounts`;

export const getAccounts = async (
  query?: string
): Promise<{result: AccountsQueryResponse; resultCount: number}> => {
  const response: AxiosResponse<{
    result: AccountsQueryResponse;
    resultCount: number;
  }> = await axios.get(`${GET_ACCOUNTS_URL}?${query}`);

  return response.data;
};

export const getMecanizouAccounts = (
  query?: string
): Promise<MecanizouAccountsQueryResponse> => {
  return axios
    .get(`${GET_ACCOUNTS_URL}/mecanizou${query ? `?${query}` : ''}`)
    .then((d: AxiosResponse<MecanizouAccountsQueryResponse>) => d.data);
};

export const showAccount = (uid: string): Promise<Account> => {
  return axios
    .get(`${GET_ACCOUNTS_URL}/${uid}`)
    .then((d: AxiosResponse<Account>) => d.data);
};

export const createAccount = (body: any): Promise<CreateAccountResponse> => {
  return axios
    .post(`${GET_ACCOUNTS_URL}`, body)
    .then((d: AxiosResponse<CreateAccountResponse>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const inviteAccount = (body: any): Promise<InvitedUser> => {
  return axios
    .post(`${GET_ACCOUNTS_URL}/invite`, body)
    .then((d: AxiosResponse<InvitedUser>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const deleteAccount = (uid: string): Promise<Account> => {
  return axios
    .delete(`${GET_ACCOUNTS_URL}/${uid}`)
    .then((d: AxiosResponse<Account>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const identificateUserAccount = async (): Promise<any> => {
  return await axios
    .get(`${API_URL}/me`)
    .then((d: AxiosResponse<Account>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const updateAccount = (uid: string, body: any): Promise<Account> => {
  return axios
    .put(`${GET_ACCOUNTS_URL}/${uid}`, body)
    .then((d: AxiosResponse<Account>) => d.data)
    .catch((e) => {
      throw e;
    });
};

export const changePassword = async (changePasswordBody: any): Promise<any> => {
  try {
    return await axios.put(
      `${GET_ACCOUNTS_URL}/change-password`,
      changePasswordBody
    );
  } catch (error) {
    throw error;
  }
};

export const getClientAccountUsersByAccountUid = (
  accountUid: string,
  query?: string
): Promise<CustomerAccount[]> => {
  return axios
    .get(`${GET_ACCOUNTS_URL}/${accountUid}/users?${query}`)
    .then((d: AxiosResponse<CustomerAccount[]>) => d.data)
    .catch((e) => {
      throw e;
    });
};
